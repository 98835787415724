<template>
  <v-dialog v-model="dialog" max-width="900" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <!-- close btn -->
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- title -->
        <v-toolbar-title v-if="dag"
          >{{ dag.dag_id }} <v-icon type="button" tag="a" style="text-decoration:none" :href="`${dag.link}&as_root=true`">mdi-fan</v-icon></v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <!-- dag details -->
      <v-container class="justify-center">
        <v-row class="mx-auto">
          <v-col cols="6">
            <v-text-field disabled label="Data/Hora de Início do Ciclo" :value="dateFormat(dag.start_date)" required outlined />
            <v-text-field disabled label="Data/Hora Estimada de Fim do Ciclo" :value="dateFormat(dag.end_date)" required outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="dag.operator" disabled label="Operator" required outlined />
            <v-text-field v-model="dag.task_id" disabled label="Pipeline Task" required outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="dag.try_number" disabled label="Tentativas de Retry" required outlined />
            <v-text-field v-model="dag.pid" disabled label="PID" required outlined />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="dag.link" disabled label="DAG Link" required outlined />
            <v-text-field v-model="dag.pool" disabled label="Pool" required outlined />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    dag: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    dateFormat(date) {
      if (!date) return '-'
      return this.$moment(date).format('YYYY-MM-DD, HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>
.v-row {
  padding: 20px;
}

.v-switch {
  margin: 20px;
}
</style>
